import revive_payload_client_lx6XA3u2ph from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.3.0_db0@0.2.1_eslint@8.51.0_ioredis@5.4.2_ma_pyf3aitgv3lgs4ljrhbtmphm6q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kvQcHdQp4r from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.3.0_db0@0.2.1_eslint@8.51.0_ioredis@5.4.2_ma_pyf3aitgv3lgs4ljrhbtmphm6q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6SvQthg4iG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.3.0_db0@0.2.1_eslint@8.51.0_ioredis@5.4.2_ma_pyf3aitgv3lgs4ljrhbtmphm6q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qYX3vU99K3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.3.0_db0@0.2.1_eslint@8.51.0_ioredis@5.4.2_ma_pyf3aitgv3lgs4ljrhbtmphm6q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4mreL8U5iV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.3.0_db0@0.2.1_eslint@8.51.0_ioredis@5.4.2_ma_pyf3aitgv3lgs4ljrhbtmphm6q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_MqQrjmPeLj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.3.0_db0@0.2.1_eslint@8.51.0_ioredis@5.4.2_ma_pyf3aitgv3lgs4ljrhbtmphm6q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_miNQS0TpiD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.3.0_db0@0.2.1_eslint@8.51.0_ioredis@5.4.2_ma_pyf3aitgv3lgs4ljrhbtmphm6q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_Vh6oNUEFBS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.3.0_db0@0.2.1_eslint@8.51.0_ioredis@5.4.2_ma_pyf3aitgv3lgs4ljrhbtmphm6q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vyRpmz2RW4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@babel+core@7.26.0_@parcel+watcher@2.5.0_@types+node@22.3.0_graphq_yorimblibxfo27wyjg6g6ukzam/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/plugins/sentry.ts";
import zod_q7cfI0sipZ from "/opt/buildhome/repo/plugins/zod.ts";
import onGqlError_y1vICByWIg from "/opt/buildhome/repo/plugins/onGqlError.ts";
import router_client_W01CLn04zL from "/opt/buildhome/repo/plugins/router.client.ts";
export default [
  revive_payload_client_lx6XA3u2ph,
  unhead_kvQcHdQp4r,
  router_6SvQthg4iG,
  payload_client_qYX3vU99K3,
  navigation_repaint_client_4mreL8U5iV,
  check_outdated_build_client_MqQrjmPeLj,
  chunk_reload_client_miNQS0TpiD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Vh6oNUEFBS,
  plugin_vyRpmz2RW4,
  sentry_3AyO8nEfhE,
  zod_q7cfI0sipZ,
  onGqlError_y1vICByWIg,
  router_client_W01CLn04zL
]